import { DEFAULT_LIMIT } from "../../../constants/table";
import { ErrorBoundaryComponent } from "../ErrorBoundaryComponent";
import { get } from "lodash";

class BaseRelatedListComponent extends ErrorBoundaryComponent {
  state = {
    page: 1,
    limit: DEFAULT_LIMIT,
    search: "",
    sortOrder: {
      column: "number",
      isAscending: false
    }
  };

  componentDidMount() {
    this.handleRefresh();
  }

  handleExportClick = type => {
    const { objects } = this.props;
    this.props.exportObjects(type, objects, this.state);
  };

  handlePageChange = (event, paginationData) => {
    this.setState({ page: paginationData.activePage }, () => {
      const objectId = get(this.props, "object.id");
      if (this.props.updateSortFilterLimit) {
        this.props.updateSortFilterLimit(objectId, this.state);
      }
    });
  };

  handleSortChange = (column, isAscending) => {
    this.setState({ sortOrder: { column, isAscending: !isAscending } }, () => {
      const objectId = get(this.props, "object.id");
      if (this.props.updateSortFilterLimit) {
        this.props.updateSortFilterLimit(objectId, this.state);
      }
    });
  };

  handleSearchChange = event => {
    if (event && event.currentTarget) {
      const search = event.currentTarget.value;
      this.setState({ search, page: 0 }, () => {
        const objectId = get(this.props, "object.id");
        if (this.props.updateSortFilterLimit) {
          this.props.updateSortFilterLimit(objectId, this.state);
        }
      });
    }
  };

  handleFilterChange = (column, value) => {
    const { filter } = this.state;
    const newFilter = { ...filter, [column]: value };
    if(!value) {
      delete newFilter[column];
    }
    this.setState({ filter: newFilter }, () => {
      const objectId = get(this.props, "object.id");
      if (this.props.updateSortFilterLimit) {
        this.props.updateSortFilterLimit(objectId, this.state);
      }
    });
  };

  handleLimitChange = limit => {
    this.setState({ limit, page: 0 }, () => {
      const objectId = get(this.props, "object.id");
      if (this.props.updateSortFilterLimit) {
        this.props.updateSortFilterLimit(objectId, this.state);
      }
    });
  };

  handleRefresh = () => {
    const { fetchObjects, isLoadingAll, object } = this.props;

    let objectId = this.props.objectId || get(object, "id");

    if (objectId && !isLoadingAll) {
      fetchObjects(objectId, this.state);
    }
  };
}

export const RelatedListComponent = BaseRelatedListComponent;
